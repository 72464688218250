<template>
  <div class="space-y-4">
    <asom-alert title="Content unavailable">
      <p class="text-sm">
        You are not allowed to view the contents of this page
      </p>
    </asom-alert>
  </div>
</template>

<script>
export default {
  name: 'Error',
}
</script>

<style>

</style>